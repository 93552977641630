import { QuestionnaireDefinition, AnswerPath } from '@breathelife/questionnaire-engine';
import { DynamicPdfDataGetRequest, DynamicPdfType, Language, Theme } from '@breathelife/types';

import ApiService from './ApiService';

export async function fetchDynamicPdfData(
  applicationId: string,
  dynamicPdfType: DynamicPdfType,
  language: Language,
): Promise<DynamicPdfDataGetRequest<QuestionnaireDefinition, AnswerPath>> {
  const insuranceProductsResponse = await ApiService.getDynamicPdfData<QuestionnaireDefinition, AnswerPath>(
    applicationId,
    dynamicPdfType,
    language,
  );
  return insuranceProductsResponse.data;
}

export async function fetchTheme(options: { mgaId?: string }): Promise<Theme> {
  if (options.mgaId) {
    const response = await ApiService.fetchThemeForMga(options.mgaId);
    return response.data;
  }
  const response = await ApiService.fetchDefaultTheme();
  return response.data;
}

import { ReactElement, Fragment } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { PayerEntity } from '@breathelife/types';
import { PdfFieldGroupHeader, PdfPaddedGridItem } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { obfuscateBankAccountNumber } from '../../../../helpers/formatting';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

// TODO: Update test fields once payor entity is implemented
export const PAYOR_INFORMATION_TEST_IDS = {
  FIRST_NAME_FIELD: 'payorInformation:firstNameField',
  LAST_NAME_FIELD: 'payorInformation:lastNameField',
  FULL_NAME_FIELD: 'payorInformation:fullNameField',
  PHONE_NUMBER_FIELD: 'payorInformation:phoneNumberField',
  EMAIL_FIELD: 'payorInformation:emailField',
  BANK_NUMBER_FIELD: 'payorInformation:bankNumberField',
  ADDRESS_FIELD: 'payorInformation:addressField',
  ADDRESS_2_FIELD: 'payorInformation:address2Field',
  CITY_FIELD: 'payorInformation:cityField',
  PROVINCE_OR_STATE_FIELD: 'payorInformation:provinceOrStateField',
  POSTAL_CODE_FIELD: 'payorInformation:postalCodeField',
};

export function PayorInformation(): ReactElement | null {
  const { application, payorInformation = [], useIndividualNameFields = false } = usePdfContext();
  const { lang: language } = application;

  const fullName = (firstName?: string, lastName?: string): string => {
    return [firstName, lastName].filter(Boolean).join(' ');
  };

  const bankNumber = (pad: any): string => {
    if (pad) {
      const { branchNumber, financialInstitutionNumber, accountNumber } = pad;
      if (branchNumber && financialInstitutionNumber && accountNumber) {
        return obfuscateBankAccountNumber(branchNumber, financialInstitutionNumber, accountNumber);
      }
    }
    return '';
  };

  return (
    <Box mb={3}>
      {payorInformation.map((payor: PayerEntity, index: number) => (
        <Fragment key={index}>
          <PdfFieldGroupHeader data-testid='PayorInformation'>
            {' '}
            {translate('payorInformation.header', { locale: language })}
          </PdfFieldGroupHeader>

          <Grid container spacing={3} alignItems='flex-start'>
            {useIndividualNameFields && (
              <Fragment>
                {!!payor.firstName && (
                  <PdfPaddedGridItem item xs={4}>
                    <QuestionAnswerGroupA
                      testID={PAYOR_INFORMATION_TEST_IDS.FIRST_NAME_FIELD}
                      labelText={translate('payorInformation.firstName', { locale: language })}
                      value={payor.firstName}
                    ></QuestionAnswerGroupA>
                  </PdfPaddedGridItem>
                )}
                {!!payor.lastName && (
                  <PdfPaddedGridItem item xs={4}>
                    <QuestionAnswerGroupA
                      testID={PAYOR_INFORMATION_TEST_IDS.LAST_NAME_FIELD}
                      labelText={translate('payorInformation.lastName', { locale: language })}
                      value={payor.lastName}
                    ></QuestionAnswerGroupA>
                  </PdfPaddedGridItem>
                )}
              </Fragment>
            )}
            {!useIndividualNameFields && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.FULL_NAME_FIELD}
                  labelText={translate('payorInformation.fullName', { locale: language })}
                  value={fullName(payor.firstName, payor.lastName)}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!payor.phoneNumber && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.PHONE_NUMBER_FIELD}
                  labelText={translate('payorInformation.phoneNumber', { locale: language })}
                  value={payor.phoneNumber}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!payor.email && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.EMAIL_FIELD}
                  labelText={translate('payorInformation.email', { locale: language })}
                  value={payor.email}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {payor.pad && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.BANK_NUMBER_FIELD}
                  labelText={translate('payorInformation.bankNumber', { locale: language })}
                  value={bankNumber(payor.pad)}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!payor.address && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.ADDRESS_FIELD}
                  labelText={translate('payorInformation.address', { locale: language })}
                  value={payor.address}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!payor.address2 && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.ADDRESS_2_FIELD}
                  labelText={translate('payorInformation.address2', { locale: language })}
                  value={payor.address2}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!payor.city && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.CITY_FIELD}
                  labelText={translate('payorInformation.city', { locale: language })}
                  value={payor.city}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!payor.provinceOrState && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.PROVINCE_OR_STATE_FIELD}
                  labelText={translate('payorInformation.provinceOrState', { locale: language })}
                  value={payor.provinceOrState}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}

            {!!payor.postalCode && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={PAYOR_INFORMATION_TEST_IDS.POSTAL_CODE_FIELD}
                  labelText={translate('payorInformation.provinceOrState', { locale: language })}
                  value={payor.postalCode}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
          </Grid>
        </Fragment>
      ))}
    </Box>
  );
}

import { ReactElement, Fragment } from 'react';

import { AnnuitantEntity, LineOfBusinessName, ProposedInsuredEntity } from '@breathelife/types';
import { PdfDocumentTitle } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { formatDateWithTime } from '../../../../helpers/date';

function hasSuffix(
  applicantInformation: ProposedInsuredEntity | AnnuitantEntity,
): applicantInformation is ProposedInsuredEntity {
  return (applicantInformation as ProposedInsuredEntity).suffix !== undefined;
}

export function CarrierInformation(): ReactElement {
  const { applicantInformation, application, timezone = 'America/Toronto' } = usePdfContext();
  const { submissionDate, createdAt, refNo, lang: language } = application;
  const {
    title: insuredTitle,
    firstName,
    middleName,
    lastName,
    email,
  } = applicantInformation || ({} as ProposedInsuredEntity);

  const suffix = applicantInformation && hasSuffix(applicantInformation) ? applicantInformation.suffix : undefined;

  const fullName = [insuredTitle, firstName, middleName, lastName, suffix].filter(Boolean).join(' ');

  const pdfTitle =
    application.lineOfBusiness === LineOfBusinessName.annuity
      ? translate('pdfTitle.lineOfBusiness.annuity.title')
      : translate('pdfTitle.lineOfBusiness.life.title');

  return (
    <Fragment>
      <PdfDocumentTitle>{pdfTitle}</PdfDocumentTitle>
      {createdAt && (
        <p>
          <strong>{translate('carrierInformation.applicationCreatedOn', { locale: language })}</strong>:{' '}
          {formatDateWithTime(createdAt, language, timezone, false)}
        </p>
      )}
      {submissionDate && (
        <p>
          <strong>{translate('carrierInformation.applicationCompletedOn', { locale: language })}</strong>:{' '}
          {formatDateWithTime(submissionDate, language, timezone, false)}
        </p>
      )}
      <p>
        <strong>{translate('carrierInformation.insuredName', { locale: language })}</strong>: {fullName}
      </p>
      <p>
        <strong>{translate('carrierInformation.insuredEmail', { locale: language })}</strong>: {email}
      </p>
      <p>
        <strong>{translate('carrierInformation.referenceNumber', { locale: language })}</strong>: {refNo}
      </p>
      <p>
        <strong>{translate('carrierInformation.id', { locale: language })}</strong>:{' '}
        {application.private.userReportDigest}
      </p>
    </Fragment>
  );
}

import dayjs from 'dayjs';
import { ReactElement, Fragment } from 'react';

import { Box, Grid } from '@breathelife/mui';
import { OwnerEntity } from '@breathelife/types';
import { PdfFieldGroupHeader, PdfPaddedGridItem } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

// TODO: Update test fields once owner entity is implemented
export const OWNER_INFORMATION_TEST_IDS = {
  FIRST_NAME_FIELD: 'OwnerInformation:firstNameField',
  LAST_NAME_FIELD: 'OwnerInformation:lastNameField',
  FULL_NAME_FIELD: 'OwnerInformation:fullNameField',
  PHONE_NUMBER_FIELD: 'OwnerInformation:phoneNumberField',
  EMAIL_FIELD: 'OwnerInformation:emailField',
  DATE_OF_BIRTH_FIELD: 'OwnerInformation:dateOfBirthField',
  ADDRESS_FIELD: 'OwnerInformation:addressField',
  ADDRESS_2_FIELD: 'ownerInformation:address2Field',
  CITY_FIELD: 'ownerInformation:cityField',
  PROVINCE_OR_STATE_FIELD: 'ownerInformation:provinceOrStateField',
  POSTAL_CODE_FIELD: 'ownerInformation:postalCodeField',
};

export function OwnerInformation(): ReactElement | null {
  const { application, ownerInformation = [], useIndividualNameFields = false } = usePdfContext();

  const { lang: language } = application;

  const fullName = (firstName?: string, lastName?: string): string => {
    return [firstName, lastName].filter(Boolean).join(' ');
  };

  return (
    <Box mb={3}>
      {ownerInformation.map((owner: OwnerEntity, index: number) => (
        <Fragment key={index}>
          <PdfFieldGroupHeader data-testid='OwnerInformation'>
            {' '}
            {translate('ownerInformation.header', { locale: language })}
          </PdfFieldGroupHeader>

          <Grid container spacing={3} alignItems='flex-start'>
            {useIndividualNameFields && (
              <Fragment>
                {!!owner.firstName && (
                  <PdfPaddedGridItem item xs={4}>
                    <QuestionAnswerGroupA
                      testID={OWNER_INFORMATION_TEST_IDS.FIRST_NAME_FIELD}
                      labelText={translate('ownerInformation.firstName', { locale: language })}
                      value={owner.firstName}
                    ></QuestionAnswerGroupA>
                  </PdfPaddedGridItem>
                )}
                {!!owner.lastName && (
                  <PdfPaddedGridItem item xs={4}>
                    <QuestionAnswerGroupA
                      testID={OWNER_INFORMATION_TEST_IDS.LAST_NAME_FIELD}
                      labelText={translate('ownerInformation.lastName', { locale: language })}
                      value={owner.lastName}
                    ></QuestionAnswerGroupA>
                  </PdfPaddedGridItem>
                )}
              </Fragment>
            )}
            {!useIndividualNameFields && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.FULL_NAME_FIELD}
                  labelText={translate('ownerInformation.fullName', { locale: language })}
                  value={fullName(owner.firstName, owner.lastName)}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {owner.dateOfBirth && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.DATE_OF_BIRTH_FIELD}
                  labelText={translate('ownerInformation.dateOfBirth', { locale: language })}
                  value={dayjs.utc(owner.dateOfBirth).format('YYYY-MM-DD')}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!owner.phoneNumber && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.PHONE_NUMBER_FIELD}
                  labelText={translate('ownerInformation.phoneNumber', { locale: language })}
                  value={owner.phoneNumber}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!owner.email && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.EMAIL_FIELD}
                  labelText={translate('ownerInformation.email', { locale: language })}
                  value={owner.email}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!owner.address && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.ADDRESS_FIELD}
                  labelText={translate('ownerInformation.address', { locale: language })}
                  value={owner.address}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!owner.address2 && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.ADDRESS_2_FIELD}
                  labelText={translate('ownerInformation.address2', { locale: language })}
                  value={owner.address2}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!owner.city && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.CITY_FIELD}
                  labelText={translate('ownerInformation.city', { locale: language })}
                  value={owner.city}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
            {!!owner.provinceOrState && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.PROVINCE_OR_STATE_FIELD}
                  labelText={translate('ownerInformation.provinceOrState', { locale: language })}
                  value={owner.provinceOrState}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}

            {!!owner.postalCode && (
              <PdfPaddedGridItem item xs={4}>
                <QuestionAnswerGroupA
                  testID={OWNER_INFORMATION_TEST_IDS.POSTAL_CODE_FIELD}
                  labelText={translate('ownerInformation.provinceOrState', { locale: language })}
                  value={owner.postalCode}
                ></QuestionAnswerGroupA>
              </PdfPaddedGridItem>
            )}
          </Grid>
        </Fragment>
      ))}
    </Box>
  );
}

import { ReactElement } from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@breathelife/mui';
import { ParticipantRoles } from '@breathelife/types';
import { PdfDocumentTitle } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { ESignature } from './ESignature';

const SignatureContainer = styled.div`
  & {
    break-inside: avoid;
  }
`;

export function ESignatureContainer(): ReactElement | null {
  const { application, signatureData } = usePdfContext();
  const { lang: language } = application;

  if (typeof signatureData === 'undefined') {
    return null;
  }

  const { signers, eSignatureFieldType } = signatureData;

  return (
    <Box mb={3}>
      <SignatureContainer>
        <PdfDocumentTitle>{translate('signature.header', { locale: language })}</PdfDocumentTitle>
        <Grid container spacing={3}>
          {(Object.keys(signers) as Array<ParticipantRoles>).map((role) => {
            const signersWithRole = signers[role];

            return (
              signersWithRole &&
              signersWithRole.length > 0 && (
                <Grid key={role} item xs={3}>
                  <ESignature signeeType={role} fieldType={eSignatureFieldType} />
                </Grid>
              )
            );
          })}
        </Grid>
      </SignatureContainer>
    </Box>
  );
}

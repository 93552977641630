import { ReactElement } from 'react';
import styled from 'styled-components';

import { Box, Grid } from '@breathelife/mui';
import { PdfFieldGroupHeader, PdfPaddedGridItem } from '@breathelife/ui-components';

import usePdfContext from '../../../../Context/usePdfContext';
import { translate } from '../../../../Localization/Localizer';
import { QuestionAnswerGroupA } from './Styles/PdfQuestionAnswerGroupA';

export const INSURANCE_FIRM_INFORMATION_TEST_IDS = {
  NAME_FIELD: 'InsuranceFirmInformation:nameField',
  PRIMARY_PHONE_NUMBER_FIELD: 'InsuranceFirmInformation:primaryPhoneNumberField',
  FREE_PHONE_NUMBER_FIELD: 'InsuranceFirmInformation:freePhoneNumberField',
  EMAIL_FIELD: 'InsuranceFirmInformation:emailField',
  ADDRESS_FIELD: 'InsuranceFirmInformation:addressField',
  WEBSITE_URL_FIELD: 'InsuranceFirmInformation:websiteUrlField',
};

const SectionContainer = styled.div`
  break-inside: avoid;
`;

export function InsuranceFirmInformation(): ReactElement | null {
  const { application, productsEntity } = usePdfContext();
  const { lang: language } = application;
  if (!productsEntity?.products?.length) {
    return null;
  }

  const insuranceFirm = productsEntity.products[0].firm;

  const areAllInsuranceFirmsEqual = productsEntity.products.every(
    (item) => item.firm.name[language] === insuranceFirm.name[language],
  );

  if (!areAllInsuranceFirmsEqual) {
    throw new Error('Pdf was not generated: selected products belong to different firms');
  }

  const { name, primaryPhoneNumber, tollFreePhoneNumber, email, address, websiteUrl } = insuranceFirm;

  return (
    <Box mb={3}>
      <SectionContainer>
        <PdfFieldGroupHeader data-testid='InsuranceFirmInformation'>
          {translate('insuranceFirmInformation.header', { locale: language })}
        </PdfFieldGroupHeader>
        <Grid container spacing={3} alignItems='flex-start'>
          {name[language] && (
            <PdfPaddedGridItem item xs={4}>
              <QuestionAnswerGroupA
                testID={INSURANCE_FIRM_INFORMATION_TEST_IDS.NAME_FIELD}
                labelText={translate('insuranceFirmInformation.name', { locale: language })}
                value={name[language] || ''}
              />
            </PdfPaddedGridItem>
          )}
          {primaryPhoneNumber && (
            <PdfPaddedGridItem item xs={4}>
              <QuestionAnswerGroupA
                testID={INSURANCE_FIRM_INFORMATION_TEST_IDS.PRIMARY_PHONE_NUMBER_FIELD}
                labelText={translate('insuranceFirmInformation.primaryPhoneNumber', { locale: language })}
                value={primaryPhoneNumber}
              />
            </PdfPaddedGridItem>
          )}
          {tollFreePhoneNumber && (
            <PdfPaddedGridItem item xs={4}>
              <QuestionAnswerGroupA
                testID={INSURANCE_FIRM_INFORMATION_TEST_IDS.FREE_PHONE_NUMBER_FIELD}
                labelText={translate('insuranceFirmInformation.tollFreePhoneNumber', { locale: language })}
                value={tollFreePhoneNumber}
              />
            </PdfPaddedGridItem>
          )}
          {email && (
            <PdfPaddedGridItem item xs={4}>
              <QuestionAnswerGroupA
                testID={INSURANCE_FIRM_INFORMATION_TEST_IDS.EMAIL_FIELD}
                labelText={translate('insuranceFirmInformation.email', { locale: language })}
                value={email}
              />
            </PdfPaddedGridItem>
          )}
          {address?.[language] && (
            <PdfPaddedGridItem item xs={4}>
              <QuestionAnswerGroupA
                testID={INSURANCE_FIRM_INFORMATION_TEST_IDS.ADDRESS_FIELD}
                labelText={translate('insuranceFirmInformation.address', { locale: language })}
                value={address?.[language] || ''}
              />
            </PdfPaddedGridItem>
          )}
          {websiteUrl && (
            <PdfPaddedGridItem item xs={4}>
              <QuestionAnswerGroupA
                testID={INSURANCE_FIRM_INFORMATION_TEST_IDS.WEBSITE_URL_FIELD}
                labelText={translate('insuranceFirmInformation.website', { locale: language })}
                value={websiteUrl}
              />
            </PdfPaddedGridItem>
          )}
        </Grid>
      </SectionContainer>
    </Box>
  );
}

import ReactHtmlParser from 'html-react-parser';
import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { Grid, GridSize } from '@breathelife/mui';
import { DynamicPdfComponentName } from '@breathelife/types';
import { PdfFieldText, PdfLabelText, PdfValueText } from '@breathelife/ui-components';

import usePdfContext from '../../../../../Context/usePdfContext';
import getComponentSettings from '../../../../../helpers/getComponentSettings';

type Props = {
  value?: string;
  fieldText?: string;
  labelText?: string;
  testID?: string;
  isSignature?: boolean;
  isOptionGroup?: boolean;
  isUnselectedOption?: boolean;
  valueWeight?: number;
  alignLabelWithValue?: boolean;
  fieldWidth?: GridSize;
  labelWidth?: GridSize;
  valueWidth?: GridSize;
};

const PdfResizedGridContainer = styled(Grid)<{ isOptionGroup?: boolean }>`
  &&&& {
    ${({ isOptionGroup }) => {
      return isOptionGroup
        ? css`
            margin-bottom: 8px;
          `
        : '';
    }}
  }
`;

const eSignatureStyling = css`
  padding: 3px 0px 0px 20px;
  display: block;
  height: 40px;
`;

const greyOutUnselectedOption = css`
  opacity: 40%;
`;

const borderSelectedOption = css`
  background: #f6f6f6;
  border: 1px solid black;
  border-radius: 4px;
`;

const greyBackground = css`
  background: #f6f6f6;
`;

const ValueContainer = styled.div<{
  isSignature?: boolean;
  isUnselectedOption?: boolean;
  isOptionGroup?: boolean;
}>`
  padding: 6px 8px 6px 8px;
  height: auto;
  display: flex;
  justify-content: left;
  align-items: center;
  ${({ isSignature }) => {
    return isSignature ? eSignatureStyling : '';
  }}
  ${({ isUnselectedOption, isOptionGroup }) => {
    if (isOptionGroup) {
      return isUnselectedOption ? '' : borderSelectedOption;
    } else {
      return isUnselectedOption ? greyOutUnselectedOption : greyBackground;
    }
  }}
`;

export function QuestionAnswerGroupA(props: Props): ReactElement {
  const { componentSelection = [] } = usePdfContext();
  const questionnaireSettings = getComponentSettings(componentSelection, DynamicPdfComponentName.Questionnaire);
  const { enableOptionGroupMargin = true } = questionnaireSettings;
  const {
    fieldText,
    value,
    labelText,
    valueWeight = 400,
    isSignature = false,
    isOptionGroup = false,
    isUnselectedOption = false,
    alignLabelWithValue = false,
    fieldWidth = 12,
    labelWidth = 12,
    valueWidth = 12,
    testID = '',
  } = props;

  return (
    <PdfResizedGridContainer container isOptionGroup={isOptionGroup && enableOptionGroupMargin}>
      {fieldText && (
        <Grid item xs={fieldWidth}>
          <PdfFieldText>{ReactHtmlParser(fieldText)}</PdfFieldText>
        </Grid>
      )}
      {labelText && (
        <Grid item xs={labelWidth}>
          <PdfLabelText isSignature={isSignature} alignLabelWithValue={alignLabelWithValue}>
            {ReactHtmlParser(labelText)}
          </PdfLabelText>
        </Grid>
      )}
      <Grid className={'valueText'} item xs={valueWidth}>
        <ValueContainer isSignature={isSignature} isUnselectedOption={isUnselectedOption} isOptionGroup={isOptionGroup}>
          <PdfValueText
            weight={valueWeight}
            isSignature={isSignature}
            data-testid={testID}
            isOptionGroup={isOptionGroup}
          >
            {value}
          </PdfValueText>
        </ValueContainer>
      </Grid>
    </PdfResizedGridContainer>
  );
}
